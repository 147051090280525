import { defineStore } from 'pinia'
import type { NotificationType } from '@ui/composables/useNotificationsUi'
import {
  SectionDataDefaultSections,
  useCustomerStore,
} from '@customer/stores/customer'
import { useNotification } from '@base/composables/useNotifications'
import type { NotificationComponentType } from '@base/stores/notifications.types'
import { useEcomGqlUserErrors } from '@ecom/composables/useEcomGqlUserErrors/useEcomGqlUserErrors'
import type { GqlError } from 'nuxt-graphql-client'
import type { CartItemInterfaceExtended, CustomerCart } from '@ecom/types/types'
import { ErpStatus, ProductType } from '@ecom/types/product.types'
import { type M2CartDivisionMethods, M2CartDivisionMethodsEnum } from '@ecom/composables/checkout/useCartDivision/useCartDivision.types'
import { useCartQueries } from '@ecom/composables/checkout/useCartQueries'
import { useCartCustomerCodes } from '@ecom/composables/checkout/useCartCustomerCodes/useCartCustomerCodes'
import {
  findProductsInCartBySkus,
  findProductsInCartByUid,
  findSingleProductInCartByUid,
} from '@ecom/helpers/cart'
import { useCartShipping } from '@ecom/composables/checkout/useCartShipping/useCartShipping'
import useProductQuantity from '@ecom/composables/product/useProductQuantity'
import { useProductPrice } from '@ecom/composables/product/useProductPrice/useProductPrice'
import { isPriceValid } from '@ecom/composables/product/useProductPrice/useProductPrice.helpers'
import type { AddToCartOverload, CustomerCartErrors, ProductAddableToCart } from './cart.types'
import { NO_CART_ID } from './cart.types'
import { ProductStockStatus } from '#gql/default'
import type {
  AddProductsToCartOutput,
  CartItemInput,
  CartItemUpdateInput,
  UpdateCartItemsInput,
} from '#gql'

export const useCartStore = defineStore('cart', () => {
  const { $i18n, callHook } = useNuxtApp()
  const { customerData } = useCustomer()
  // Method is available on $i18n composer instance
  const { t } = $i18n
  const { handleUserError, handleCartItemsError } = useEcomGqlUserErrors()
  const { getProductFinalPrice } = useProductPrice()

  const RawlplugAttributesDefaults = [
    {
      attribute_code: 'brand',
    },
    {
      attribute_code: 'ecommerce_packing_type',
    },
    {
      attribute_code: 'erp_packaging_and_quantity',
    },
    {
      attribute_code: 'erp_quantity_in_package',
    },
  ]
  const GetDefaultCartDivisionOption = () => M2CartDivisionMethodsEnum.ALL
  const pending = ref<boolean>(false)
  const loadingItems = ref<string[]>([])
  const errorState = ref<CustomerCartErrors>({
    addItem: null,
    load: null,
    updateItem: null,
    loadAgreements: null,
    placeOrder: null,
  })
  const loadingLayer = ref({
    visible: false,
    message: t('ecom_please_wait'),
  })
  const cart = ref<CustomerCart | null | undefined>()
  const cartDivisionMethod = ref<M2CartDivisionMethods>(
    GetDefaultCartDivisionOption(),
  )

  const sectionDataCartId = computed(() => {
    return customerData.value?.cart?.id ?? null
  })

  const cartItemsCount = computed(() => cart.value?.items?.length ?? 0)
  const cartItemsProductsCategories = computed(() => {
    if (!cart.value?.items) {
      return []
    }

    const categories: string[] = []

    cart.value.items.forEach((item) => {
      if (Array.isArray(item?.product?.categories) && item.product.categories.length) {
        const uids = item.product.categories.map(category => category?.uid ?? '')
        categories.push(...uids)
      }
    })

    return categories
  })

  const cartItemsWithProductQuantityDataChanged = computed(() => {
    const skus: Set<string> = new Set()

    if (!cart.value?.items) {
      return skus
    }

    cart.value.items.forEach((item) => {
      if (Array.isArray(item?.errors) && item.errors.length) {
        item.errors.forEach((error) => {
          if ((error?.code ?? '') === 'ITEM_QTY') {
            if (item.product?.sku) {
              skus.add(item.product.sku)
            }
          }
        })
      }
    })

    return skus
  })

  const onlyVirtualProductsInCart = computed(() => cart.value?.items?.every((item => item?.product?.product_typename === ProductType.VIRTUAL)) ?? false)

  const customerStore = useCustomerStore()
  const { refreshSectionData } = customerStore
  const addToCartNotificationType = 'toast' as NotificationComponentType
  const { showNotification } = useNotification(addToCartNotificationType)

  const getCart = async () => {
    pending.value = true
    errorState.value.load = null
    try {
      const { getCustomerCart } = useCartQueries()
      const { customerCart } = await getCustomerCart({
        attributes: RawlplugAttributesDefaults,
      })

      setCart(customerCart)
      return customerCart
    }
    catch (error) {
      errorState.value.load = error
    }
    finally {
      pending.value = false
    }
  }

  const cartShippingInformation = computed(() => {
    if (!cart.value) {
      return null
    }
    const { getShippingInformation } = useCartShipping()
    return getShippingInformation(cart.value)
  })

  /**
   * Mutation method - use addToCart, because there are few more steps
   * in order to add item to cart correctly
   */
  const _buildPayload = <T>(params: T, camelCase = true) => {
    // in some cases magento 2 params are written in camel case, in some other in snake case
    const cartIdVariable = (camelCase ? 'cartId' : 'cart_id')
    const cartItemsVariable = (camelCase ? 'cartItems' : 'cart_items')

    const payload = {
      ...params,
      [cartItemsVariable]: params[cartItemsVariable as keyof typeof params],
      [cartIdVariable]:
        sectionDataCartId.value
        || params[cartIdVariable as keyof typeof params]
        || NO_CART_ID,
    }

    return payload
  }

  /**
   * Method checks if products were successfully added to cart and list skus of those products under 'success' and 'error' keys.
   * We take into account the number of products we want to add, the current quantity of the product already present
   * in the cart, and the quantity in the cart just after adding the products
   */

  const _getStatusOfProductsToBeAddedToCart = (productsToAdd: { sku?: string | null, quantity: number }[], productsAfterAddingToCart: NonNullable<AddProductsToCartOutput['cart']>['items'], cartItemsStateBeforeAddingToCart: CartItem[]) => {
    const results = productsToAdd.map((productToAdd) => {
      // Check if product we want to add is in the cart after 'addToCart' request
      const productFoundInCartAfterAddition = (productsAfterAddingToCart ?? []).find(productAfterAddingToCart => productToAdd.sku === productAfterAddingToCart?.product?.sku)

      // Check if product we want to add was already in the cart, before adding it
      const productFoundInCartBeforeAddition = (cartItemsStateBeforeAddingToCart ?? []).find(item => item.product_sku === productToAdd.sku)

      // If product was already in the cart, add the quantity already present with the one we want to add
      const expectedQuantity = (productFoundInCartBeforeAddition ? Number(productFoundInCartBeforeAddition.qty) : 0) + Number(productToAdd.quantity)

      return {
        sku: productToAdd?.sku ?? '',
        // If 'expectedQuantity` is the same after `addToCart` request, it means product was successfully added to cart
        status: productFoundInCartAfterAddition && productFoundInCartAfterAddition.quantity === expectedQuantity ? 'success' : 'error',
      }
    })

    const categorizedResults = results.reduce((acc, item) => {
      acc[item.status as keyof typeof acc].push(item.sku)
      return acc
    }, { success: [] as string[], error: [] as string[] })

    return categorizedResults
  }

  const _addProductsToCart = async (
    params: AddToCartOverload | AddToCartOverload[],
    refetchCart = false,
  ) => {
    pending.value = true
    errorState.value.addItem = null

    try {
      const { addProductsToCart } = useCartQueries()

      const payload = _buildPayload<AddToCartOverload | AddToCartOverload[]>(
        params,
      )

      const cartCopyBeforeUpdate = JSON.parse(JSON.stringify(customerData.value?.cart?.items))

      if (!payload?.cartId || payload.cartId === NO_CART_ID) {
        const response = await getCart()
        if (response?.id) {
          Object.assign(payload, { ...payload, cartId: response.id })
        }
      }
      const updatedCart = await retry(() => addProductsToCart(payload), 2, 1000)
      const updatedCartItems = (updatedCart?.cart?.items ?? []) as NonNullable<AddProductsToCartOutput['cart']>['items']
      const productsStatuses = _getStatusOfProductsToBeAddedToCart(payload.cartItems, updatedCartItems, cartCopyBeforeUpdate)

      if (updatedCart) {
        if ('user_errors' in updatedCart && Array.isArray(updatedCart.user_errors) && updatedCart.user_errors.length) {
          handleUserError(updatedCart.user_errors)
        }

        const cartItems = updatedCart.cart?.items ?? []
        if (cartItems && cartItems.length) {
          handleCartItemsError(cartItems)
        }
      }

      if (updatedCart?.cart?.id) {
        if (Array.isArray(productsStatuses?.success) && productsStatuses.success.length) {
          productsStatuses.success.forEach((sku: string) => {
            showCartNotification({
              type: 'success',
              message: `${t(
                'ecom_added_to_cart',
              )} - ${sku}`,
            })
          })
        }

        if (refetchCart) {
          await getCart()
        }
      }

      return updatedCart
    }
    catch (error) {
      const { $parseGqlError } = useNuxtApp()
      const { logOutCustomer } = useCustomer()
      const gqlError = $parseGqlError(error as GqlError)
      errorState.value.addItem = gqlError

      // TODO: TEMPORARY FIX ON FRONTEND WITH CART ERROR - REMOVE WHEN FIXED ON MAGENTO BACKEND
      if (
        gqlError?.message?.includes(
          'The current user cannot perform operations on cart',
        )
      ) {
        await logOutCustomer({
          redirectTo: true,
        })

        showCartNotification({
          type: 'error',
          message: t(
            'ecom_session_has_expired_log_in_again',
          ),
        })
        return
      }
      // TODO: TEMPORARY FIX ON FRONTEND WITH CART ERROR - REMOVE WHEN FIXED ON MAGENTO BACKEND

      showCartNotification({
        type: 'error',
        message:
          gqlError?.message
          ?? t('something_went_wrong'),
      })
      return
    }
    finally {
      pending.value = false
    }
  }

  const addToCart = async <T extends ProductAddableToCart>({ product, quantity, refetchCart = false }: { product: T, quantity: number, refetchCart?: boolean }) => {
    if (!product?.uid || !product?.sku) {
      showCartNotification({
        type: 'error',
        message: t('something_went_wrong'),
      })
      return
    }

    if (!canAddToCart(product)) {
      showCartNotification({
        type: 'error',
        message: t('ecom_product_is_not_available'),
      })
      return
    }

    loadingItems.value.push(product.uid)

    try {
      await _addProductsToCart(
        {
          cartItems: [{ sku: product.sku, quantity }],
        },
        refetchCart,
      )
      callHook('ecom:cart-add', {
        sku: product.sku,
        quantity,
      })
    }
    finally {
      loadingItems.value = loadingItems.value.filter(
        item => item !== product.uid,
      )

      if (!loadingItems.value.length) {
        await refreshSectionData({
          sections: ['cart', 'messages'],
        })
      }
    }
  }

  /**
   * Method used for adding multiple items to cart
   */
  const addToCartMoreItems = async (data: {
    product: ProductAddableToCart
    quantity: number
  }[], requireProductUid = true, enableCanAddToCartCheck = true) => {
    if (!Array.isArray(data) || !data.length) {
      return
    }

    const itemsToAdd = [] as CartItemInput[]
    data.forEach((el) => {
      if ((requireProductUid && !el.product?.uid) || !el.product?.sku) {
        showCartNotification({
          type: 'error',
          message: `${t('something_went_wrong')} - ${
              el.product?.sku || el.product?.uid
            }`,
        })
        return
      }

      if (enableCanAddToCartCheck && !canAddToCart(el.product)) {
        const errorMessage = `${t(
            'ecom_product_is_not_available',
          )} - ${el.product?.sku}`
        showCartNotification({
          type: 'error',
          message: errorMessage,
          timeout: false,
        })
        errorState.value.addItem = errorMessage
        return
      }

      loadingItems.value.push(requireProductUid ? el.product?.uid ?? '' : el.product?.sku ?? '')
      itemsToAdd.push({
        sku: el.product?.sku,
        quantity: el.quantity,
      })
    })
    if (!Array.isArray(itemsToAdd) || !itemsToAdd.length) {
      showCartNotification({
        type: 'error',
        message: t('ecom_no_products_to_add'),
      })
      return
    }

    try {
      const response = await _addProductsToCart({
        cartItems: itemsToAdd,
      })
      return response
    }
    finally {
      data.forEach((el) => {
        loadingItems.value = loadingItems.value.filter((item) => {
          if (requireProductUid) {
            return item !== el.product?.uid
          }
          return item !== el.product?.sku
        })
      })

      if (!loadingItems.value?.length) {
        await refreshSectionData({
          sections: SectionDataDefaultSections,
        })
      }
    }
  }

  /**
   * Internal method with API call - creates payload and updates provided products` quantity
   */
  const _updateCartItems = async (params: UpdateCartItemsInput['cart_items']) => {
    pending.value = true
    errorState.value.updateItem = null
    try {
      const { updateCartItems } = useCartQueries()

      const payload = _buildPayload<UpdateCartItemsInput['cart_items']>(params, false)

      const updatedCart = await updateCartItems({
        input: payload,
        attributes: RawlplugAttributesDefaults,
      })

      if (updatedCart) {
        if ('user_errors' in updatedCart && Array.isArray(updatedCart.user_errors) && updatedCart.user_errors.length) {
          handleUserError(updatedCart.user_errors)
        }

        const cartItems = updatedCart.cart?.items ?? []
        if (cartItems && cartItems.length) {
          handleCartItemsError(cartItems)
        }
      }

      return updatedCart
    }
    catch (error) {
      errorState.value.updateItem = error
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Method uses `_updateCartItems` under the hood - used for creating payload and updating quantity
   * of provided products.
   */
  const updateCartItems = async (data: { cartItem: CartItemInterfaceExtended, quantity: number } | { cartItem: CartItemInterfaceExtended, quantity: number }[]) => {
    if (!data) {
      return null
    }

    let cartItems: CartItemUpdateInput[] = []
    let cartItemsUids: string[] = []

    const cartItemsData = Array.isArray(data) ? data : [data]

    if (!cartItemsData?.length) {
      return
    }

    cartItems = cartItemsData.map(item => ({
      cart_item_uid: item.cartItem.uid,
      quantity: item.quantity,
    }))

    cartItemsUids = cartItemsData.map(item => item?.cartItem?.uid ?? '')
    loadingItems.value.push(...cartItemsUids)

    try {
      const { cart: customerCart = null }
        = (await _updateCartItems({
          cart_items: cartItems,
        })) || {}

      setCart(customerCart)
      return customerCart
    }
    finally {
      loadingItems.value = loadingItems.value.filter(
        item => !cartItemsUids?.includes(item),
      )

      if (!loadingItems.value?.length) {
        await refreshSectionData({
          sections: SectionDataDefaultSections,
        })
      }
      cartItems = []
    }
  }

  /**
   * Internal method with API call - creates payload and removes provided products from cart
   * @param { string[] } cartItemsUids
   * @param { string } [cartId]
   */
  const _removeItemsFromCart = async (
    cartItemsUids: string[],
    cartId?: string,
  ) => {
    pending.value = true
    errorState.value.updateItem = null
    try {
      const { removeItemsFromCart } = useCartQueries()

      return await removeItemsFromCart({
        input: {
          cart_id: sectionDataCartId.value || cartId || NO_CART_ID,
          cart_items_uids: cartItemsUids,
        },
      })
    }
    catch (error) {
      errorState.value.updateItem = error
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Method uses `_removeItemsFromCart` under the hood - used for removing provided products.
   * Customer order number will be deleted if all products are removed
   */
  const removeCartItems = async (
    data: CartItemInterfaceExtended | CartItemInterfaceExtended[] | null,
    refetchCart = true,
  ) => {
    if (!data) {
      return
    }

    const params = Array.isArray(data) ? data : [data]
    const { setCustomerOrderNumber } = useCartCustomerCodes()
    const cartItemsUids = params.map(item => item?.uid ?? '')

    loadingItems.value.push(...cartItemsUids)

    try {
      const response = await _removeItemsFromCart(cartItemsUids)
      if (response?.cart?.id && refetchCart) {
        await getCart()
      }

      if (
        cart.value?.id
        && Array.isArray(cart.value?.items)
        && !cart.value?.items.length
        && Boolean(cart.value?.customer_order_nr)
      ) {
        // Remove customer order number if there are no items in cart
        await setCustomerOrderNumber(cart.value.id, '', false)
      }

      callHook('ecom:cart-remove', {
        skuArray: cartItemsUids,
      })

      return response
    }
    finally {
      loadingItems.value = loadingItems.value.filter(
        item => !cartItemsUids?.includes(item),
      )

      if (!loadingItems.value?.length) {
        await refreshSectionData({
          sections: SectionDataDefaultSections,
        })
      }
    }
  }

  /**
   * Method used for placing an order
   * @param {string} cartId
   */
  const placeOrder = async (cartId: string) => {
    if (!cartId) {
      return
    }
    pending.value = true
    errorState.value.placeOrder = null
    try {
      const { placeOrder } = useCartQueries()
      const response = await placeOrder({ input: { cart_id: cartId } })

      if (response) {
        if ('errors' in response && Array.isArray(response.errors) && response.errors.length) {
          handleUserError(response.errors)
        }

        if (response.order?.order_number) {
          cart.value = null
        }
      }

      return response
    }
    catch (error) {
      errorState.value.placeOrder = error
    }
    finally {
      pending.value = false
    }
  }

  /**
   * Method used for checking if product can be added to cart
   */
  const canAddToCart = (product: ProductAddableToCart | null, quantity = 1): boolean => {
    const isAvailable
      = Boolean(ProductStockStatus[product?.stock_status as unknown as ProductStockStatus]) || false

    const onlyXleftInStock = product?.only_x_left_in_stock ?? 0

    const isAnyItemLeft
      = product?.only_x_left_in_stock === null
        ? true
        : quantity <= onlyXleftInStock

    return isAvailable && isAnyItemLeft
  }

  const shouldItemBeDisabled = ({ product, quantity = 1 }: { product: ProductAddableToCart | null, quantity?: number }) => {
    // If product doesn't have individual quantity data - disable button ('LOCKED')
    const productQuantity = useProductQuantity(product).productQuantityData.value
    if (!product?.uid || !productQuantity) {
      return true
    }

    const isWithdrawnFromSale = product?.erp_status === ErpStatus.WITHDRAWN_FROM_SALE

    if ((productQuantity.qty < 1 && isWithdrawnFromSale) || !isPriceValid(getProductFinalPrice(product))) {
      return true
    }

    return loadingItems.value.includes(product.uid) || !canAddToCart(product, quantity)
  }

  const setCart = (customerCart: CustomerCart) => {
    if (!customerCart) {
      return
    }
    cart.value = customerCart
  }

  const setCartDivisionMethod = (code: M2CartDivisionMethods) => {
    cartDivisionMethod.value = code
  }

  const resetCartDivisionMethod = () =>
    setCartDivisionMethod(GetDefaultCartDivisionOption())

  const resetCartStore = () => {
    resetCartDivisionMethod()
  }

  const showLoadingLayer = ({
    visible,
    message,
  }: {
    visible: boolean
    message?: string
  }) => {
    loadingLayer.value.visible = visible

    if (message) {
      loadingLayer.value.message = message
    }
  }

  const showCartNotification = ({
    type,
    message,
    timeout = 3000,
  }: {
    type: NotificationType
    message: string
    timeout?: number | false
  }) => {
    showNotification({
      id: crypto.randomUUID(),
      type,
      message,
      closable: true,
      timeout,
    })
  }

  return {
    cart,
    cartItemsCount,
    cartItemsProductsCategories,
    cartItemsWithProductQuantityDataChanged,
    getCart,
    cartShippingInformation,
    addToCart,
    shouldItemBeDisabled,
    addToCartMoreItems,
    updateCartItems,
    removeCartItems,
    findSingleProductInCartByUid,
    findProductsInCartByUid,
    findProductsInCartBySkus,
    canAddToCart,
    showCartNotification,
    placeOrder,
    setCart,
    RawlplugAttributesDefaults,
    cartDivisionMethod,
    setCartDivisionMethod,
    loadingItems,
    pending,
    errors: errorState,
    showLoadingLayer,
    loadingLayer,
    resetCartDivisionMethod,
    resetCartStore,
    onlyVirtualProductsInCart,
  }
})
